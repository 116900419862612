/*--------------------------------

nucleo-icons Web Font - built using nucleoapp.com
License - nucleoapp.com/license/

-------------------------------- */
@font-face {
  font-family: 'nucleo-icons';
  src: url('~assets/fonts/nucleo-icons.eot');
  src: url('~assets/fonts/nucleo-icons.eot') format('embedded-opentype'), url('~assets/fonts/nucleo-icons.woff2') format('woff2'), url('~assets/fonts/nucleo-icons.woff') format('woff'), url('~assets/fonts/nucleo-icons.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
/*------------------------
	base class definition
-------------------------*/
.nc-icon {
  display: inline-block;
  font: normal normal normal 14px/1 'nucleo-icons';
  font-size: inherit;
  speak: none;
  text-transform: none;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/*------------------------
  change icon size
-------------------------*/
.nc-icon.lg {
  font-size: 1.33333333em;
  vertical-align: -16%;
}
.nc-icon.x2 {
  font-size: 2em;
}
.nc-icon.x3 {
  font-size: 3em;
}
/*----------------------------------
  add a square/circle background
-----------------------------------*/
.nc-icon.square,
.nc-icon.circle {
  padding: 0.33333333em;
  vertical-align: -16%;
  background-color: #eee;
}
.nc-icon.circle {
  border-radius: 50%;
}
/*------------------------
  list icons
-------------------------*/
.nc-icon-ul {
  padding-left: 0;
  margin-left: 2.14285714em;
  list-style-type: none;
}
.nc-icon-ul > li {
  position: relative;
}
.nc-icon-ul > li > .nc-icon {
  position: absolute;
  left: -1.57142857em;
  top: 0.14285714em;
  text-align: center;
}
.nc-icon-ul > li > .nc-icon.lg {
  top: 0;
  left: -1.35714286em;
}
.nc-icon-ul > li > .nc-icon.circle,
.nc-icon-ul > li > .nc-icon.square {
  top: -0.19047619em;
  left: -1.9047619em;
}
/*------------------------
  spinning icons
-------------------------*/
.nc-icon.spin {
  -webkit-animation: nc-icon-spin 2s infinite linear;
  -moz-animation: nc-icon-spin 2s infinite linear;
  animation: nc-icon-spin 2s infinite linear;
}
@-webkit-keyframes nc-icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes nc-icon-spin {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
@keyframes nc-icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/*------------------------
  rotated/flipped icons
-------------------------*/
.nc-icon.rotate-90 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.nc-icon.rotate-180 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.nc-icon.rotate-270 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
}
.nc-icon.flip-y {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0);
  -webkit-transform: scale(-1, 1);
  -moz-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
.nc-icon.flip-x {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: scale(1, -1);
  -moz-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  -o-transform: scale(1, -1);
  transform: scale(1, -1);
}
/*------------------------
	icons
-------------------------*/
/* icons */
$icons: (
  'road-2': 'ea05',
  'wifi': 'ea06',
  'wifi-2': 'ea07',
  'playground': 'ea09',
  'car-parking': 'ea0a',
  'property-agreement': 'ea0b',
  'mobile-phone': 'ea0d',
  'letter': 'ea0e',
  'square-pin': 'ea0f',
  'map-pin': 'ea10',
  'beach-umbrella': 'ea11',
  'rowing': 'ea13',
  'boat-small-02': 'ea14',
  'binoculars': 'ea15',
  'telescope': 'ea16',
  'mountain': 'ea17',
  'logo-whatsapp': 'ea18',
  'mobile-contact': 'ea19',
  'beach-umbrella-2': 'ea1b',
  'store': 'ea1c',
  'cutlery': 'ea1d',
  'skiing': 'ea1e',
  'watering-plants': 'ea1f',
  'rowing-1': 'ea20',
  'email': 'ea21',
);

@function unicode($str) {
  @return unquote("\"\\#{$str}\"");
}

@each $name, $code in $icons {
  .icon-#{""+$name}::before {
    content: unicode($code);
  }
}